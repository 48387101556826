import React, { Component } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";

import Footer from "../component/footer/Footer";

import 'react-medium-image-zoom/dist/styles.css'

import SliderOne from "../component/slider/SliderOne";
import Helmet from "../component/common/Helmet";
import Section1 from "../component/sections/Section1";
import Section2 from "../component/sections/Section2";
import Section3 from "../component/sections/Section3";
import Section4 from "../component/sections/Section4";
import Section5 from "../component/sections/Section5";
import Section6 from "../component/sections/Section6";
import Section7 from "../component/sections/Section7";
import Section8 from "../component/sections/Section8";
import Section9 from "../component/sections/Section9";
import Section10 from "../component/sections/Section10";
import Section11 from "../component/sections/Section11";
import Section12 from "../component/sections/Section12";
import Section13 from "../component/sections/Section13";
import Section14 from "../component/sections/Section14";
import Section61 from "../component/sections/Section61";
import LanguageSelector from "../component/LanguageSelector";
import locales from "../locales";
// import BrandTwo from "../elements/BrandTwo";

class MainDemo extends Component{
    render(){
        // const PostList = BlogContent.slice(0 , 3);
        return(
            <div className="active-dark">
                <LanguageSelector/>

                <Helmet pageTitle={locales.t('slider.title')} />
                {/*<Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />*/}

                {/* Start Slider Area   */}
                <div className="slider-wrapper">
                    <SliderOne />
                </div>
                {/* End Slider Area   */}

                <div className="about-area ptb--120 bg_color--1">
                    <Section1 />
                </div>
                <div className="about-area ptb--120 bg_color--1">
                    <Section2 />
                </div>
                <div className="about-area ptb--120 bg_color--1">
                    <Section3 />
                </div>
                <div className="about-area ptb--120 bg_color--1">
                    <Section4 />
                </div>
                <div className="about-area ptb--120 bg_color--1">
                    <Section5 />
                </div>
                <div className="about-area ptb--120 bg_color--1">
                    <Section6 />
                </div>
                <div className="about-area ptb--120 bg_color--1">
                    <Section61 />
                </div>
                <div className="about-area ptb--120 bg_color--1">
                    <Section7 />
                </div>
                <div className="about-area ptb--120 bg_color--1">
                    <Section8 />
                </div>
                <div className="about-area ptb--120 bg_color--1">
                    <Section9 />
                </div>
                <div className="about-area ptb--120 bg_color--1">
                    <Section10 />
                </div>
                <div className="about-area ptb--120 bg_color--1">
                    <Section11 />
                </div>
                <div className="about-area ptb--120 bg_color--1">
                    <Section12 />
                </div>
                <div className="about-area ptb--120 bg_color--1">
                    <Section13 />
                </div>
                <div className="about-area ptb--120 bg_color--1">
                    <Section14 />
                </div>

                {/*<div className="rn-brand-area bg_color--1 ptb--120">*/}
                {/*    <div className="container">*/}
                {/*        <div className="row">*/}
                {/*            <div className="col-lg-12 col-md-12 col-sm-12 col-12">*/}
                {/*                <div className="section-title text-center mb--30">*/}
                {/*                    <h2 className="title">Наши партнеры</h2>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="row">*/}
                {/*            <div className="col-lg-12">*/}
                {/*                <BrandTwo />*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />
                
            </div>
        )
    }
}
export default MainDemo;