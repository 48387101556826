import React, {Component} from "react";
import {FiGrid} from "react-icons/all";
import Zoom from 'react-medium-image-zoom'
import locales from "../../locales";

class Section11 extends Component {
    render() {
        const images = [
            '/assets/images/sections/11-1.webp',
            '/assets/images/sections/11-2.webp',
            '/assets/images/sections/11-3.webp',
        ];

        return (
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--20 align-items-center">
                            <div className="col-lg-12 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <div className="icon">
                                            <FiGrid/>
                                        </div>
                                        <h2 className="title">{locales.t('section-2.module-9')}</h2>
                                    </div>
                                    <div className="row justify-content-center custom-images-list">
                                        {images.map(
                                            (image, index) => <div className="col-lg-6 col-md-6 col-sm-12 col-12 mt-3 custom-images-list__item"
                                                                   key={index}>
                                                <Zoom
                                                    overlayBgColorStart="rgba(0, 0, 0, 0.1)"
                                                    overlayBgColorEnd="rgba(0, 0, 0, 0.7)"
                                                >
                                                    <img
                                                        src={image}
                                                        alt=""
                                                    />
                                                </Zoom>
                                            </div>
                                        )}
                                    </div>
                                    <div className="purchase-btn mt--50">
                                        <a
                                            className="btn-transparent"
                                            href={locales.t('pdf-link')}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {locales.t('download-pdf')}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default Section11;