import React, {Component} from "react";
import {BiBrain} from "react-icons/all";
import {FiCheck} from "react-icons/fi";
import locales from "../../locales";

const COUNT_MODULES = 3;

class Section4 extends Component {
    render() {
        const title = locales.t('section-4.title');
        const description = locales.t('section-4.desc');

        const modules = [...Array(COUNT_MODULES).keys()].map((i) =>
            locales.t(`section-4.module-${i + 1}`),
        );

        return (
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-12 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <div className="icon">
                                            <BiBrain/>
                                        </div>
                                        <h2 className="title">{title}</h2>
                                        <p>
                                            {description}
                                        </p>

                                        <ul className="list-style--1">
                                            {modules.map((name, index) => {
                                                return <li key={ index }><FiCheck /> {name}</li>;
                                            })}
                                        </ul>

                                        <div className="purchase-btn">
                                            <a
                                                className="btn-transparent"
                                                href={locales.t('pdf-link')}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {locales.t('download-pdf')}
                                            </a>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default Section4;