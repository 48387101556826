import React, {Component} from "react";
import ServiceOne from "../../elements/service/ServiceOne";
import locales from '../../locales'
import ModalVideo from 'react-modal-video';

class SliderOne extends Component {
    constructor() {
        super();

        this.state = {
            isVideoOpen: false,
        };

        this.openModal = this.openModal.bind(this);
    }

    openModal () {
        this.setState({isVideoOpen: true})
    }

    render() {
        return (
            <div className="slider-activation">
                {/* Start Single Slide */}
                <div className="slider-paralax slide d-flex align-items-center bg_image bg_image--vernedsoft"
                     data-black-overlay="6">
                    <div className="container position-relative">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner">
                                    <h1 className="title theme-gradient main-slide-title">
                                        {locales.t('slider.title')}
                                        <br/>
                                        «VRMedSoft»
                                    </h1>
                                    <div
                                        className="slide-btn"
                                        style={{
                                            marginTop: '1rem',
                                            marginBottom: '1rem',
                                        }}
                                    >
                                        <a
                                            className="btn-default"
                                            href="#"
                                            onClick={this.openModal}
                                        >
                                            {locales.t('slider.demo')}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Start Service Area */}
                        <div className="service-wrapper service-white">
                            <ServiceOne/>
                        </div>
                        {/* End Service Area */}

                        <ModalVideo
                            channel='youtube'
                            isOpen={this.state.isVideoOpen}
                            videoId='sxMxgGOzGc8'
                            onClose={() => this.setState({isVideoOpen: false})}
                        />

                    </div>
                </div>
                {/* End Single Slide */}
            </div>
        );
    }
}

export default SliderOne;