import React, {Component} from "react";
import {GoNote} from "react-icons/all";
import locales from "../../locales";

class Section3 extends Component {
    render() {
        const title = locales.t('section-3.title');

        const description = locales.t('section-3.desc');

        return (
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-12 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <div className="icon">
                                            <GoNote/>
                                        </div>
                                        <h2 className="title">{title}</h2>
                                        <p>
                                            {description}
                                        </p>
                                        <div className="purchase-btn">
                                            <a
                                                className="btn-transparent"
                                                href={locales.t('pdf-link')}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {locales.t('download-pdf')}
                                            </a>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default Section3;