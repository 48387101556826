import React, {Component} from "react";
import {FaVk, FaTelegram, FaWhatsapp, FaYoutube} from "react-icons/fa";
import locales from "../../locales";

const SocialShare = [
    {Social: <FaTelegram/>, link: 'https://t.me/Poligonator'},
    {Social: <FaVk/>, link: 'https://vk.com/public211631687'},
    {Social: <FaWhatsapp/>, link: 'https://wa.me/79381259709'},
    {Social: <FaYoutube/>, link: 'https://www.youtube.com/channel/UCZQX_wel24A2maqOPO-RxQQ'},
]

class Footer extends Component {
    render() {
        return (
            <React.Fragment>
                <footer className="footer-area footer-style-01 bg_color--6">
                    {/* Start Footer Area  */}
                    <div className="footer-wrapper ptb--70">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                    <div className="ft-text">
                                        <p>
                                            {locales.t('footer.copyright')}
                                            <br/>
                                            {locales.t('footer.company-desc')}
                                            &nbsp;
                                            <a
                                                href="https://xn--80afolmcdcbsu.xn--p1ai/"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {locales.t('footer.company')}
                                            </a>
                                        </p>
                                    </div>
                                </div>

                                {/* Start Single Widget  */}
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt_md--40 mt_sm--40">
                                    <div className="d-flex flex-column align-items-end">
                                        <h4>{locales.t('footer.contacts')}</h4>
                                        <ul className="ft-link">
                                            <li>Email: <a href="mailto:vrmedsoft@gmail.com">vrmedsoft@gmail.com</a></li>
                                            <li>{locales.t('footer.phone')}: <a href="tel:+7 938 125-97-09">+7 938
                                                125-97-09</a></li>
                                        </ul>

                                        <div className="social-share-inner mt--20">
                                            <ul className="social-share social-style--2 d-flex justify-content-start liststyle">
                                                {SocialShare.map((val, i) => (
                                                    <li key={i}><a target="_blank" href={`${val.link}`}>{val.Social}</a></li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                {/* End Single Widget  */}
                            </div>
                        </div>
                    </div>
                    {/* End Footer Area  */}
                </footer>
            </React.Fragment>
        )
    }
}

export default Footer;