import React, {Component} from "react";
import {BsInfo} from "react-icons/all";
import locales from "../../locales";

const COUNT_DESCRIPTIONS = 6;

class Section1 extends Component {
    render() {
        const title = locales.t('section-1.title');

        const descriptions = [...Array(COUNT_DESCRIPTIONS).keys()].map((i) => ({
            title: locales.t(`section-1.desc-title-${i + 1}`),
            info: locales.t(`section-1.desc-${i + 1}`),
        }));

        return (
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-12 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <div className="icon">
                                            <BsInfo/>
                                        </div>
                                        <h2 className="title">{title}</h2>
                                        {descriptions.map(
                                            (description, index) => <p className="description" key={index}>
                                                <span style={{textDecoration: 'underline'}}>
                                                    {description.title}
                                                </span>
                                                <br/>
                                                {description.info}
                                            </p>
                                        )
                                        }
                                        <div className="purchase-btn">
                                            <a
                                                className="btn-transparent"
                                                href={locales.t('pdf-link')}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {locales.t('download-pdf')}
                                            </a>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default Section1;