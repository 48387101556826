import React, {Component} from "react";
import locales, { langs } from "../locales";

class LanguageSelector extends Component {
    render() {
        return (
            <div className="language-selector">
                <div style={{
                    position: 'absolute',
                    right: '50px',
                    top: '50px',
                }}>
                    {langs.map((lang) => (<a
                        key={lang.key}
                        href={`#lang-${lang.key}`}
                        className={`language-selector__item ${locales.language === lang.key ? 'language-selector__item_active' : ''}`}
                        onClick={() => locales.changeLanguage(lang.key)}
                    >
                        {lang.title}
                    </a>))}
                </div>
            </div>
        );
    }
}

export default LanguageSelector;