import React, { Component } from "react";
import {FiGrid} from "react-icons/all";
import locales from "../../locales";

const COUNT_MODULES = 12;

class Section2 extends Component{
    render(){
        const modules = [...Array(COUNT_MODULES).keys()].map((i) =>
            locales.t(`section-2.module-${i + 1}`),
        );

        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--20 align-items-center">
                            <div className="col-lg-12 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <div className="icon">
                                            <FiGrid/>
                                        </div>
                                        <h2 className="title">
                                            {locales.t('section-2.title')}
                                        </h2>
                                    </div>
                                    <div className="row">
                                        {modules.map((module, index) => <div className="col-lg-6 col-md-12 col-sm-12 col-12" key={index}>
                                            <div className="about-us-list">
                                                <h5 className="title">{module}</h5>
                                            </div>
                                        </div>)}
                                    </div>
                                    <div className="purchase-btn mt--50">
                                        <a
                                            className="btn-transparent"
                                            href={locales.t('pdf-link')}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {locales.t('download-pdf')}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Section2;