import React, {Component} from "react";
import {AiOutlineCheck} from "react-icons/all";
import {FiCheck} from "react-icons/fi";
import locales from "../../locales";

const COUNT_MODULES = 11;

class Section14 extends Component {
    render() {
        const title = locales.t('section-14.title');

        const items = [...Array(COUNT_MODULES).keys()].map((i) =>
            locales.t(`section-14.item-${i + 1}`),
        );

        return (
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-12 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <div className="icon">
                                            <AiOutlineCheck/>
                                        </div>
                                        <h2 className="title">{title}</h2>

                                        <ul className="list-style--1">
                                            {items.map((item, index) => {
                                                return <li key={ index }><FiCheck /> {item}</li>;
                                            })}
                                        </ul>

                                        <div className="purchase-btn">
                                            <a
                                                className="btn-transparent"
                                                href={locales.t('pdf-link')}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {locales.t('download-pdf')}
                                            </a>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default Section14;